import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import ResponsiveImage from "@/components/ResponsiveImage";
import ContentModal from "@/components/editorial/content-modal";
import SecondaryHeadline from "@/components/headlines/home/secondary";
import LinkWithArrow from "@/components/links/link-with-arrow-kam";
import ModalWrap from "@/components/modal-wrap";

import styles from "./EditorialArticleScroller.module.scss";

const EditorialScrollerItem = ({ data, selectModalFunc }) => {
  const itemRef = useRef(null);
  const itemContentRef = useRef(null);

  const {
    fields: {
      callToActionText,
      content,
      DescriptionNew: description,
      label,
      slug,
      spotifyLink,
      title,
    },
  } = data;

  /**
   *
   */
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        end: "center center",
        scrub: true,
        start: "top center",
        trigger: itemRef.current,
      },
    });

    tl.add(
      gsap.to(itemContentRef.current, {
        duration: 0.3,
        ease: "power1.inOut",
        opacity: 1,
        transform: "translateY(0px)",
      }),
      0
    );
  });

  return (
    <section
      className={styles.section}
      data-attr-module-name="module-editorial-article-scroller"
      data-attr-scroll={slug}
      ref={itemRef}
    >
      <div className={styles.photo}>
        <ResponsiveImage
          altText={data?.fields?.backgroundImage?.fields?.title}
          defaultImage={data?.fields?.backgroundImageMobile}
          mdImage={data?.fields?.backgroundImage}
        />
      </div>

      <div className={styles.content} ref={itemContentRef}>
        {label && <span className={`${styles.eyeBrow}`}>{label}</span>}

        <SecondaryHeadline styles={styles} text={title} />

        <div
          className={`${styles.description}`}
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(description),
          }}
        />

        {spotifyLink && (
          <div className={styles.spotifyLink}>
            <iframe
              allow={"encrypted-media"}
              frameBorder={0}
              height={360}
              src={`https://open.spotify.com/embed?uri=${spotifyLink}`}
              width={`100%`}
            />
          </div>
        )}

        {callToActionText && (
          <div className={styles.cta}>
            <LinkWithArrow
              data={{
                fields: {
                  text: callToActionText,
                },
              }}
              onClickFunc={(e) => {
                e.preventDefault();
                selectModalFunc(content);
              }}
              onKeyPressFunc={(e) => {
                if (e.which == 13 || e.keyCode == 13) {
                  selectModalFunc(content);

                  return false;
                }

                return true;
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

EditorialScrollerItem.propTypes = {
  data: PropTypes.object,
  selectModalFunc: PropTypes.func,
};

EditorialScrollerItem.defaultPropTypes = {
  data: {},
};

const EditorialArticleScroller = ({ articleQuery, data }) => {
  const { articleItems, anchorTag } = data;

  const [fromQuery, setFromQuery] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const router = useRouter();

  const closeModal = () => {
    if (fromQuery) {
      const {
        pathname,
        query: { slug },
      } = router;

      router.replace(`${pathname}/${slug}`, { shallow: false });
      setModalOpen(false);
      setFromQuery(false);
    } else {
      setModalOpen(false);
    }
  };

  const selectModal = (content) => {
    if (!content) {
      return;
    }

    setModalOpen(true);
    setModalContent(content);
  };

  useEffect(() => {
    if (typeof window == "undefined") {
      return;
    }

    if (articleQuery) {
      const articleSearch = articleItems.filter((item) => {
        return articleQuery === item.fields.slug;
      })[0];

      if (articleSearch) {
        const {
          fields: { content },
        } = articleSearch;

        if (content) {
          setModalContent(content);
          setModalOpen(true);
          setFromQuery(true);
        }
      }
    }
  }, [articleItems, articleQuery]);

  return (
    <article
      className={styles.editorialArticleScroller}
      data-attr-scroll={anchorTag}
      id="editorial-scroller"
    >
      {articleItems?.map((article) => (
        <EditorialScrollerItem
          data={article}
          key={article?.fields?.title}
          selectModalFunc={selectModal}
        />
      ))}

      <ModalWrap
        displayClose={true}
        onRequestClose={closeModal}
        open={modalOpen}
      >
        <ContentModal
          data={modalContent || { fields: {} }}
          onRequestClose={closeModal}
        />
      </ModalWrap>
    </article>
  );
};

EditorialArticleScroller.propTypes = {
  articleQuery: PropTypes.string,
  data: PropTypes.object,
};

EditorialArticleScroller.defaultPropTypes = {
  data: {},
};

export default EditorialArticleScroller;
